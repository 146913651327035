<template>
<div class="w">
<div class="crumbs">
  <router-link to="/">首页</router-link>
  <span>/</span>
  <router-link to="/periodicalList">行业期刊</router-link>
  <span>/</span>
  正文
</div>
<div class="content">
  <div class="title">{{info.name}}</div>
  <div class="info">
    <span class="info-source" v-if="info.source">来源：中国鼠害网</span>
    <span>发布时间：{{info.createTime}} </span>
  </div>
  <div class="intro">
    <div class="intro-img">
      <img :src="info.imgurl" />
    </div>
    <div class="intro-box">
      <div class="intro-title">{{info.subject}}</div>
      <div class="intro-intro">{{info.summary}}</div>
      <ul>
        <li><span>创刊时间：</span>{{info.startPublishTime}}</li>
        <li><span>主管单位：</span>{{info.owner}}</li>
        <li><span>发行周期：</span>{{publishCycleFilter(info.publishCycle)}}</li>
      </ul>
    </div>
  </div>
  <div class="detail">
    <div class="detail-title">期刊描述</div>
    <div v-html="info.body"></div>
  </div>
</div>
</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { yncyPeriodical } from '../api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Periodical',
  setup () {
    const info = ref({})
    const route = useRoute()

    onMounted(() => {
      yncyPeriodical({ id: route.query.id }).then(res => {
        info.value = {
          ...res.result,
          name: res.result.subject
        }
      })
    })

    const publishCycleFilter = (val) => {
      let name

      if (val === 0) {
        name = '季刊'
      } else if (val === 1) {
        name = '月刊'
      } else if (val === 2) {
        name = '周刊'
      }

      return name
    }

    return { info, publishCycleFilter }
  }
})
</script>

<style lang="scss" scoped>
.intro{
  display: flex;
  margin-bottom: 40px;

  &-img{
    width:200px;
    margin-right: 30px;
    img{
      width: 100%;
    }
  }
  &-box{
    flex: 1;
  }
  &-title{
    font-size: 22px;
    margin-bottom: 20px;
  }
  &-intro{
    margin-bottom: 20px;
    line-height: 1.2em;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  li{
    margin-bottom: 10px;
    span{
      margin-right: 10px;
    }
  }
}
.content{
  margin-bottom: 60px;
}
.detail{

  &-title{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 40px;
  }
}
.content{
  border: 1px solid #DDDDDD;
  padding: 40px 40px 60px;
}
.title{
  text-align: center;
  font-size: 22px;
}
.info{
  line-height: 38px;
  text-align: center;
  color: #999999;
  border-bottom: 1px dotted #999999;
  margin-bottom: 60px;
  &-source{
    margin-right: 20px;
  }
}
</style>
